// import type { ErrorMessageMode } from '@pkg/types'
import {request} from '@pkg/request';
// import {AccountListGetResultModel, AccountParams} from '../demo';


enum Api {
    GetAllItems = '/post_tiku_allItems',
    // Logout = '/logout',
    // GetUserInfo = '/post_tiku_UserInfo',
    // GetPermCode = '/get_tiku_PermCode',
}

/**
 * @description: user login api
 */
export function getAllItemsApi(params) {
    return request.post(
        {
            url: Api.GetAllItems,
            params
        }
    );
}

export function addItemToSchoolDbApi(params) {
    return request.post({
        url: '/post_tiku_addItemToSchoolDB',
        params
    });
}

export function addItemToPersonalDbApi(params) {
    return request.post({
        url: '/post_tiku_addItemToPersonalDB',
        params
    });
}


export function getSchoolAllItemsApi(params) {
    return request.post({
        url: '/post_tiku_getSchoolAllItems',
        params
    });
}

// 查询试卷上的所有题目
export function getPaperItemsApi(params) {
    return request.post({
        url: '/post_tiku_getPaperItems',
        params
    });
}

export function getPersonalAllItemsApi(params) {
    return request.post({
        url: '/post_tiku_getPersonalAllItems',
        params
    });
}

export function removeItemFromSchoolDbApi(params) {
    return request.post({
        url: '/post_tiku_removeItemFromSchoolDB',
        params
    });
}

export function removeItemFromPersonalDbApi(params) {
    return request.post({
        url: '/post_tiku_removeItemFromPersonalDB',
        params
    });
}


export function getVipSchoolBooksApi(params) {
    return request.post({
        url: '/post_tiku_getVipSchoolBooks',
        params
    });
}

export function addItemToHomeworkApi(params) {
    return request.post({
        url: '/post_tiku_addItemToHomework',
        params
    });
}


export function getOssPolicyApi() {
    return request.get({
        url: '/get_ossPolicy?from=tiku'
    });
}

export function uploadOssApi(oss, params) {
    return request.post({
        url: oss.host,
        headers: {'Content-Type': 'multipart/form-data'},
        params
    });
}

export function saveHomeworkApi(params) {
    return request.post({
        url: '/post_tiku_saveHomework',
        params
    });
}

export function getHomeworkListApi(params) {
    return request.post({
        url: '/post_tiku_getHomeworkList',
        params
    });
}

// 查询练习卷
export function getVipSchoolPapersExerciseListApi(params) {
    return request.post({
        url: '/post_tiku_getVipSchoolPapersExercise',
        params
    });
}



export function deleteHomeworkApi(params) {
    return request.post({
        url: '/post_tiku_deleteHomework',
        params
    });
}

export function getHomeworkByIdApi(params) {
    return request.get({
        url: '/get_tiku_getHomeworkById',
        params
    });
}

export function getHomeworkItemAnswersApi(params) {
    return request.post({
        url: '/post_getItemAnswer',
        params
    });
}

export const getAllRoleListApi = (params?) =>
    request.post({
        url: '/post_tiku_getAllRoleList',
        params,
    });

export const getAllVipSchoolsListApi = (params?) =>
    request.post({
        url: '/post_tiku_getAllVipSchoolList',
        params,
    });


export const getTeachersInSchoolApi = (params) =>
    request.get({
        url: '/post_tiku_getTeachersInSchool',
        params,
    });

export const getSchoolRolesListApi = () =>
    request.post({
        url: '/post_tiku_getRolesList',
        params: {roleType: '3'}
    });


export const saveTeacherDataApi = (params) =>
    request.post({
        url: '/post_tiku_saveTeacherData',
        params
    });

export const saveTeachersByExcelApi = (params) =>
    request.post({
        url: '/post_tiku_saveTeachersByExcel',
        params
    });

export const getSchoolTeachersListApi = (params) =>
    request.post({
        url: '/post_tiku_getSchoolTeachers',
        params
    });

export const deleteTeacherApi = (params) =>
    request.post({
        url: '/post_tiku_deleteTeacher',
        params
    });

export const deleteStudentApi = (params) =>
    request.post({
        url: '/post_tiku_deleteStudent',
        params
    });

export const getStudentsInClassroomApi = (params) =>
    request.post({
        url: '/post_tiku_getStudentsInClassroom',
        params
    });

export const saveStudentDataApi = (params) =>
    request.post({
        url: '/post_tiku_saveStudentData',
        params
    });

export const saveStudentsByExcelApi = (params) =>
    request.post({
        url: '/post_tiku_saveStudentsByExcel',
        params
    });

export const modifyPasswordApi = (params) =>
    request.post({
        url: '/post_tiku_modifyPassword',
        params
    });


export const getSchoolNameApi = (params) =>
    request.post({
        url: '/post_tiku_getSchoolName',
        params
    });

export const getStaticsApi = (params) =>
    request.get({
        url: '/get_tiku_statistics',
        params
    });

export const getAllWrongItemsByHomeworkIdApi = (params) =>
    request.get({
        url: '/get_tiku_getWrongItemsByHomeworkId',
        params
    });

export const getAllWrongItemsByChapterAndBookIdApi = (params) =>
    request.get({
        url: '/get_tiku_getWrongItemsByChapterAndBookId',
        params
    });

export const getAllWrongItemsByBookIdAndPageApi = (params) =>
    request.get({
        url: '/get_tiku_getWrongItemsByBookIdAndPage',
        params
    });

export const getAllWrongItemsByPaperExerciseApi = (params) =>
    request.get({
        url: '/get_tiku_getWrongItemsByPaperExercise',
        params
    });

export const getClassSubjectAllWrongItemsApi = (params) =>
    request.get({
        url: '/get_tiku_getClassSubjectAllWrongItems',
        params
    });


export const getClassPrintRecordsApi= (params) =>
    request.get({
        url: '/get_tiku_getClassPrintRecords',
        params
    });

export const saveBianShiTiLinkApi= (params) =>
    request.post({
        url: '/post_tiku_saveBianShiTiLink',
        params
    });

export const getBianShiTiApi= (params) =>
    request.get({
        url: '/get_tiku_getBianShiTi',
        params
    });

export const getUploadPolicyCodeApi= (params?) =>
    request.get({
        url: '/get_tiku_getUploadPolicyCode',
        params
    });


export const getUploadResultApi= (params) =>
    request.get({
        url: '/get_tiku_getUploadResult',
        params
    });

// 保存变式题难度
export const saveBianShiTiDifficultyApi= (params) =>
    request.post({
        url: '/post_tiku_saveBianShiTiDifficulty',
        params
    });

export function getAllBooksApi(params?) {
    return request.get({
        url: '/get_tiku_allBooks',
        params
    });
}

export const openVipBookApi= (params) =>
    request.post({
        url: '/post_tiku_openVipBook',
        params
    });

export const closeVipBookApi= (params) =>
    request.post({
        url: '/post_tiku_closeVipBook',
        params
    });

export function getAllClassesApi(params?) {
    return request.get({
        url: '/get_tiku_schoolAllClasses',
        params
    });
}

export const saveBookContentApi= (params) =>
    request.post({
        url: '/post_tiku_saveBookContent',
        params
    });

// 查询本学期校本作业总量
export const getSchoolBooksSumEveryTermApi= (params) =>
    request.post({
        url: '/post_tiku_getSchoolBooksSumEveryTerm',
        params
    });

// 查询本学期校本题库
export const getItemsSumEveryTermApi= (params) =>
    request.post({
        url: '/post_tiku_getItemsSumEveryTerm',
        params
    });

// 查询本学期高频错题
export const getFrequencyItemsApi= (params) =>
    request.post({
        url: '/post_tiku_getFrequencyItems',
        params
    });

// 查询各班错题总量
export const getWrongItemsInClassesApi= (params) =>
    request.post({
        url: '/post_tiku_getWrongItemsInClasses',
        params
    });

// 查询各班错题标记情况
export const getMarkSumInClassesApi= (params) =>
    request.post({
        url: '/post_tiku_getMarkSumInClasses',
        params
    });

// 查询各班标记天数情况
export const getMarkDaysInClassesApi= (params) =>
    request.post({
        url: '/post_tiku_getMarkDaysInClasses',
        params
    });


// 查询年级错题标记情况
export const getMarkSumInGradeApi= (params) =>
    request.post({
        url: '/post_tiku_getMarkSumInGrade',
        params
    });

// 查询全校错标记情况
export const getMarkSumOfSchoolApi= (params) =>
    request.post({
        url: '/post_tiku_getMarkSumOfSchool',
        params
    });

// 查询所有学校的统计数据
export const getSchoolsStatisticsApi= (params) =>
    request.post({
        url: '/post_tiku_getSchoolsStatistics',
        params
    });

/**
 * @description: getUserInfo
 */
/*
export function getUserInfo() {
  return request.post<GetUserInfoModel>(
    { url: Api.GetUserInfo },
    { errorMessageMode: 'none' },
  )
}

export function getPermCode() {
  return request.get<string[]>({ url: Api.GetPermCode })
}

export function doLogout() {
  return request.get({ url: Api.Logout })
}
*/
